import { createContext, FC, useContext, useState } from "react";

import { ConfirmModal, ConfirmModalProps } from "~/components/ConfirmModal";

export interface ModalData {
  content: React.ReactNode;
  props: Omit<ConfirmModalProps, "open">;
}
export interface ModalManagerContextType {
  createModal: (modalData: ModalData) => void;
}

export const ModalManagerContext = createContext<ModalManagerContextType>(
  null!
);

export const ModalManagerProvider: FC = ({ children }) => {
  const [modalData, setModalData] = useState<ModalData | null>(null);

  const createModal = (modalData: ModalData) => {
    setModalData(modalData);
  };

  const removeModal = () => {
    setModalData(null);
  };

  const handleClose = () => {
    removeModal();
    if (modalData) {
      modalData.props.onClose();
    }
  };

  const handleConfirm = () => {
    removeModal();
    if (modalData) {
      modalData.props.onConfirm();
    }
  };

  return (
    <ModalManagerContext.Provider value={{ createModal }}>
      {children}
      <ConfirmModal
        {...modalData?.props}
        onConfirm={handleConfirm}
        onClose={handleClose}
        open={!!modalData}
      >
        {modalData?.content}
      </ConfirmModal>
    </ModalManagerContext.Provider>
  );
};

export const useModalManager = () => {
  return useContext(ModalManagerContext);
};
