import { RollType } from "@dndbeyond/dice";
import { WithDiceRollv2 } from "@dndbeyond/dice-components";
import { RollableComponentProps } from "@dndbeyond/dice-components/dist/types/src/components/WithDiceRollv2";
import { useMemo } from "react";

export interface DigitalDiceWrapperProps extends
  Omit<RollableComponentProps, "BaseComponent"  | "useCustomizableDiceNotation">
{
}

export const DigitalDiceWrapper: React.FC<DigitalDiceWrapperProps> = ({ 
  useAdvancedMenu = true,
  rollType,
  children,
  ...props
}) => {
  const useCustomizableDiceNotation = useMemo(
    () => rollType === RollType.Damage
      || rollType === RollType.Heal,
    [rollType]
  );

  return (
    <WithDiceRollv2
        BaseComponent={(
          <>
            {children}
          </>
        )}
        useAdvancedMenu={useAdvancedMenu}
        useCustomizableDiceNotation={useCustomizableDiceNotation}
        rollType={rollType}
        {...props}
    />
  );
};
