import { FC } from "react";

import { Button } from "~/components/Button";
import { useCharacterEngine } from "~/hooks/useCharacterEngine";
import { useEquipmentMethods } from "~/subApps/builder/hooks/useEquipmentMethods";
import { Item } from "~/types";

export interface EquipmentActionsProps {
  item: Item;
  equipLabel: string;
  unequipLabel: string;
}

export const EquipmentActions: FC<EquipmentActionsProps> = ({
  item,
  equipLabel,
  unequipLabel,
}) => {
  const { itemUtils } = useCharacterEngine();
  const { onToggleEquip } = useEquipmentMethods();
  const isEquipped = itemUtils.isEquipped(item);
  const isStackable = itemUtils.isStackable(item);
  const canEquip = itemUtils.canEquip(item);
  const quantity = itemUtils.getQuantity(item);

  const handleToggleEquip = (e) => onToggleEquip(e, item);

  return (
    <>
      {isStackable && (
        <div className="equipment-list-item-callout-quantity">
          <span className="equipment-list-item-callout-quantity-extra">
            Qty
          </span>
          <span className="equipment-list-item-callout-quantity-value">
            {quantity}
          </span>
        </div>
      )}
      {canEquip && (
        <div className="equipment-list-item-callout-action">
          <Button
            onClick={handleToggleEquip}
            size="xx-small"
            color="builder-green"
            variant={isEquipped ? "solid" : "outline"}
          >
            {isEquipped ? unequipLabel : equipLabel}
          </Button>
        </div>
      )}
    </>
  );
};
