import { FC } from "react";

import { Accordion } from "~/components/Accordion";
import { ItemName } from "~/components/ItemName";
import { useCharacterEngine } from "~/hooks/useCharacterEngine";
import { EquipmentActions } from "~/subApps/builder/components/EquipmentActions";
import { useEquipmentMethods } from "~/subApps/builder/hooks/useEquipmentMethods";
import { Item } from "~/types";

import ItemDetail from "../../ItemDetail";
import { ItemListInformationCollapsible } from "../../ItemListInformationCollapsible";
import { EquipmentListItemActions } from "../EquipmentListItem";

interface ArmorListItemProps {
  item: Item;
  unequipLabel: string;
  equipLabel: string;
  showRemove?: boolean;
  showEquip?: boolean;
  showUnequip?: boolean;
  showAttuning?: boolean;
  showHeaderAction: boolean;
}

export const ArmorListItem: FC<ArmorListItemProps> = ({
  item,
  equipLabel,
  unequipLabel,
  ...props
}) => {
  const actions = useEquipmentMethods();
  const {
    ruleData,
    proficiencyBonus,
    characterTheme,
    itemUtils,
    helperUtils,
    containerLookup,
  } = useCharacterEngine();
  const image = itemUtils.getAvatarUrl(item);
  const altText = itemUtils.getName(item);
  const container = helperUtils.lookupDataOrFallback(
    containerLookup,
    itemUtils.getContainerDefinitionKey(item)
  );

  const getMetaItems = () => {
    const type = itemUtils.getType(item);
    const baseArmorName = itemUtils.getBaseArmorName(item);

    return [...(type ? [type] : []), ...(baseArmorName ? [baseArmorName] : [])];
  };

  return (
    <Accordion
      summary={<ItemName item={item} showLegacyBadge={true} />}
      summaryAction={
        <EquipmentActions
          item={item}
          equipLabel={equipLabel}
          unequipLabel={unequipLabel}
        />
      }
      summaryImage={image}
      summaryImageAlt={altText}
      summaryMetaItems={getMetaItems()}
      variant="paper"
    >
      <ItemListInformationCollapsible ruleData={ruleData} item={item} />
      <ItemDetail
        container={container}
        theme={characterTheme}
        item={item}
        ruleData={ruleData}
        showCustomize={false}
        showActions={false}
        proficiencyBonus={proficiencyBonus}
      />
      <EquipmentListItemActions item={item} {...actions} {...props} />
    </Accordion>
  );
};
