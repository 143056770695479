import { useDispatch } from "react-redux";

import { ContainerTypeEnum } from "~/constants";
import { useCharacterEngine } from "~/hooks/useCharacterEngine";
import { useUnpropagatedClick } from "~/hooks/useUnpropagatedClick";
import { useInventoryManager } from "~/tools/js/Shared/managers/InventoryManagerContext";
import { Item } from "~/types";

import { useModalManager } from "../contexts/ModalManager";

export const useEquipmentMethods = () => {
  const dispatch = useDispatch();
  const {
    containerLookup,
    containerUtils,
    definitionUtils,
    helperUtils,
    itemUtils,
    infusionUtils,
    serviceDataActions,
  } = useCharacterEngine();
  const { inventoryManager } = useInventoryManager();
  const { createModal } = useModalManager();

  const launchContainerRemovalModal = (item: Item) =>
    createModal({
      content: (
        <div className="equipment-manage__warning">
          <p>
            Removing the <strong>{itemUtils.getName(item)}</strong> will also
            remove all of its contents.
          </p>
        </div>
      ),
      props: {
        heading: `Remove ${itemUtils.getName(item)}`,
        onConfirm: () => {
          inventoryManager.handleRemove({ item });
        },
        onClose: () => {}, //Do nothing
        variant: "remove",
        size: "fit-content",
        confirmButtonText: "Remove",
      },
    });

  const onRemove = (item) => {
    const isContainer = itemUtils.isContainer(item);
    if (isContainer) {
      launchContainerRemovalModal(item);
    } else {
      inventoryManager.handleRemove({ item });
    }
  };

  const onRemoveInfusion = (item) => {
    const infusion = itemUtils.getInfusion(item);
    if (infusion) {
      const infusionId = infusionUtils.getId(infusion);
      if (infusionId === null) {
        return;
      }

      if (itemUtils.isContainer(item)) {
        const container = helperUtils.lookupDataOrFallback(
          containerLookup,
          definitionUtils.hack__generateDefinitionKey(
            ContainerTypeEnum.ITEM,
            itemUtils.getMappingId(item)
          )
        );
        if (container) {
          if (containerUtils.hasInfusions(container)) {
            // Pop up the infusion modal
            createModal({
              content: (
                <div className="equipment-manage__warning">
                  <p>
                    <strong>Notice:</strong> In accordance with Artificer
                    guidance the Waste Management Guild of Sharn (the City of
                    Towers) requires the removal of all Infusions prior to
                    disposal of any items.
                  </p>
                </div>
              ),
              props: {
                heading: `Remove ${itemUtils.getName(item)}: Infusions?`,
                onConfirm: () => {
                  dispatch(
                    serviceDataActions.infusionMappingsDestroy(
                      containerUtils.getInfusedItemMappingIds(container)
                    )
                  );
                  launchContainerRemovalModal(item);
                },
                onClose: () => {}, //Do nothing
                variant: "remove",
                size: "fit-content",
                confirmButtonText: "Remove",
              },
            });
          } else {
            // pop up the container modal
            launchContainerRemovalModal(item);
          }
        }
      } else {
        dispatch(
          serviceDataActions.infusionMappingDestroy(
            infusionId,
            infusionUtils.getInventoryMappingId(infusion)
          )
        );
      }
    }
  };

  const onUnequip = (item) => inventoryManager.handleUnequip({ item });

  const onEquip = (item) => inventoryManager.handleEquip({ item });

  const onAttune = (item) => inventoryManager.handleAttune({ item });

  const onUnattune = (item) => inventoryManager.handleUnattune({ item });

  const onQuantitySet = (item, quantity) =>
    inventoryManager.handleQuantitySet({ item, quantity });

  const onToggleEquip = useUnpropagatedClick((e, item: Item) => {
    const isEquipped = itemUtils.isEquipped(item);
    if (isEquipped) {
      if (onUnequip) onUnequip(item);
    } else {
      if (onEquip) onEquip(item);
    }
  });

  return {
    onAttune,
    onEquip,
    onQuantitySet,
    onRemove,
    onRemoveInfusion,
    onToggleEquip,
    onUnattune,
    onUnequip,
  };
};
