import { ClassAccessors } from '../Class';
import { ClassFeatureAccessors } from '../ClassFeature';
import { DataOriginTypeEnum } from '../DataOrigin';
import { getDataOrigin, getDataOriginType } from './accessors';
export function getContextData(option) {
    const dataOrigin = getDataOrigin(option);
    const dataOriginType = getDataOriginType(option);
    let classLevel;
    switch (dataOriginType) {
        case DataOriginTypeEnum.CLASS_FEATURE:
            classLevel = ClassAccessors.getLevel(dataOrigin.parent);
            break;
        default:
        // not implemented
    }
    return {
        classLevel,
    };
}
export function getLevelScale(option) {
    const dataOrigin = getDataOrigin(option);
    const dataOriginType = getDataOriginType(option);
    switch (dataOriginType) {
        case DataOriginTypeEnum.CLASS_FEATURE:
            return ClassFeatureAccessors.getLevelScale(dataOrigin.primary);
        default:
            return null;
    }
}
